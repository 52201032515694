<template>
    <div class="login-container">
        <el-form :model="loginForm" :rules="rules"
         ref="loginForm" 
         label-position="left" 
         label-width="0px" 
         :show-message="false"
         class="demo-ruleForm login-page">
            <div class="logo">
                <!-- <div class="img"><img src="../assets/images/logo.png" width="120"></div> -->
                <!-- <span class="company">工友社区宿舍管理系统</span> -->
                <!-- <span class="company-en">Northwest Branch of China Railway Construction Group Co., Ltd</span> -->
            </div>
            <h3 class="title">工友社区宿舍管理系统</h3>
            <el-form-item prop="tel">
                <el-input type="text" 
                    v-model="loginForm.tel" 
                    auto-complete="off" 
                    placeholder="用户名/电话"
                    prefix-icon="img-icon-user"
                    @blur="fieldTrim(loginForm, 'tel')"
                ></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" 
                    v-model="loginForm.password" 
                    auto-complete="off" 
                    placeholder="密码"
                    prefix-icon="img-icon-lock"
                ></el-input>
            </el-form-item>
            <el-form-item class="box-submit">
                <el-button type="primary" class="btn-submit" @click="handleSubmit" :loading="logining" :disabled="btnSubmitDisabled">登录</el-button>
            </el-form-item>
            <!-- <el-form-item style="margin: 0 auto;text-align: center;">
                <span class="demonstration" style="color: #ffffff;font-size: 14px;font-weight:bold">APP 下载</span>
            </el-form-item> -->
            <el-form-item style="margin: 0 auto;text-align: center;">
<!--                <el-image style="width: 100px; height: 100px" src="url"></el-image>-->
            </el-form-item>
        </el-form>
        <div class="copy-right"><a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2023001068号-1</a></div>
    </div>
</template>

<script>
import http from "../utils/http"
import common from "../utils/common"
// import captchaUrl from "../assets/images/login/captcha.jpg"
import menuAllList from '@/data/menu'

export default {
    name: 'login',
    data(){
        let userName;
        let tel;
        let password;
        let captcha;
        if (process.env.NODE_ENV !== 'production') {
            userName = 'admin';
            tel ='13200000001';
            password = 'ww20220913';
            captcha = 'fdfma';
        }
        return {
            logining: false,
            loginForm: {
                userName: userName,
                tel: tel,
                password: password,
                captcha: captcha,
                token: '',
            },
            captchaUrl: '',
            captchaLoadToken: null,
            rules: {
                username: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
                password: [{required: true, message: '密码不能为空', trigger: 'blur'}],
                //captcha: [{required: true, message: '验证码不能为空', trigger: 'blur'}]
            },
            checked: false,
            btnSubmitDisabled: false,
        }
    },
    created() {
        // this.loadToken();
    },
    mounted() {
        // this.captchaLoadToken = this.load('.captcha', '');
    },
    methods: {
        ...common,
        handleSubmit(){
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.logining = true;
                    http.post('v1/login', this.loginForm).then(req => {
                        this.logining = false;
                        if (req.data.code == http.SUCCESS) {
                            localStorage.setItem('token', req.data.data.token);
                            this.setUserAllInfo(req.data.data);
                            this.initDefaultUrl();
                            this.$router.push({path: this.getDefaultUrl()});
                        } else {
                            if (req.data.code == 101) {
                                this.updateCaptcha();
                            }
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.logining = false;
                        this.httpCatch(err);
                    });
                } else {
                    if (this.loginForm.username == '') {
                        this.showError(this.rules.username[0].message);
                        return false;
                    }

                    if (this.loginForm.password == '') {
                        this.showError(this.rules.password[0].message);
                        return false;
                    }

                    if (this.loginForm.captcha == '') {
                        this.showError(this.rules.captcha[0].message);
                        return false;
                    }

                    return false;
                }
            });
        },
        initDefaultUrl() {
            let defaultUrl = '';
            this.forEachTree(menuAllList, (node) => {
                if (this.hasPermission(node.tag)) {
                    node.isAuth = true;
                    if (defaultUrl === '' && node.href) {
                        defaultUrl = node.href;
                    }
                } else {
                    node.isAuth = false;
                }
            });
            this.setDefaultUrl(defaultUrl);
        },
        loadToken() {
            http.get('v1/login/validate-code/token', this.loginForm).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loginForm.token = req.data.data;
                    this.captchaUrl = http.BASE_URL + '/v1/login/validate-code/' + req.data.data;
                    if (this.captchaLoadToken != null) { 
                        this.captchaLoadToken.close();
                    }
                    this.btnSubmitDisabled = false;
                } else {
                    this.showError(req.data.msg);
                }
            });
        },
        updateCaptcha() {
            let t = '?t=' + (new Date().getTime()) + '_' + Math.floor(Math.random()*10000);
            this.captchaUrl = this.captchaUrl.replace(/$|\?.*$/, t);
        },
    }
};
</script>

<style scoped>
.logo { 
    width: 570px; position: absolute; top: -105px; text-align: left; left: 50%; margin-left: -285px;
}
.logo .img { 
    float: left; 
}
.logo .company { 
    font-size: 26px; color:#333; display: block; letter-spacing: 2px;
    line-height: 34px; padding-top: 16px;
}
.logo .company-en { 
    font-size: 14px; color:#333; 
}
h3 { 
    font-size: 26px; 
    font-weight: normal; 
    text-align: center;
    color: #000;
    letter-spacing: 2px;
    padding-bottom: 18px;
}
.login-container { 
    width: 100%;
    height: 100%;
    background: url(../assets/images/login/login-bg.jpg) no-repeat center;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
}
.login-page {
    margin: -125px 0 0 -195px;
    
    width: 320px;
    padding: 5px 35px 15px;
    
    /* border: 1px solid #eee;
    -webkit-border-radius: 5px;
    border-radius: 5px; */

    /* background-color: #fcfcfc;
    box-shadow: 0 0 25px rgba(163, 163, 163, 0.5); */

    position: absolute;
    top: 45%;
    left: 50%;
}
.login-page /deep/ .img-icon-user::after,
.login-page /deep/ .img-icon-lock::after,
.login-page /deep/ .img-icon-shield::after {
    width: 22px;
    height: 22px;
    position: relative;
    top: -2px;
}
.login-page /deep/ .img-icon-user::after {
    background: url(../assets/images/login/icon-user.png);
    background-size: 100%;
}
.login-page /deep/ .img-icon-lock::after {
    background: url(../assets/images/login/icon-lock.png);
    background-size: 100%;
}
.login-page /deep/ .img-icon-shield::after {
    background: url(../assets/images/login/icon-shield.png);
    background-size: 100%;
}
.captcha-input {
    float: left; 
    width: 192px;
}
.box-submit {
   padding-top: 5px; 
}
.btn-submit {
    width: 100%;
}
.captcha {
    margin-left: 200px;
    height: 40px;
}
.captcha img {
    width: 159px;
    height: 40px;
    float: left;
    cursor: pointer;
    border-radius: 5px;
}
.el-form-item.is-error /deep/ input {
    border-color: #DCDFE6;
}
.el-form-item.is-error /deep/ input:focus {
    border-color: #409EFF;
}
.copy-right {
    text-align: center;
    padding: 15px;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
.copy-right a {
    color: #444;
}
.copy-right a:hover {
    color: #333;
}
</style>